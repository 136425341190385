import React, { useEffect, useState } from 'react';
import { DatePicker } from '@arco-design/web-react';
import styles from './datepicker.module.css';
import { convertDateFromIsoToHTMLFormat } from '../utils/date';

export const DateRangePicker = ({ onChange = () => {}, initial_value }) => {
  const [display_value, setDisplayValue] = useState([]);

  useEffect(() => {
    if (!initial_value || !Array.isArray(initial_value) || initial_value.length !== 2) return;
    const [start, end] = initial_value;
    const range_start = convertDateFromIsoToHTMLFormat(start);
    const range_end = convertDateFromIsoToHTMLFormat(end);
    if (!range_start || !range_end) return;

    setDisplayValue([range_start, range_end]);
  }, [initial_value]);

  const handleChange = (val) => {
    setDisplayValue(val);
    onChange(val);
  };

  return (
    <DatePicker.RangePicker
      className={styles.datepicker}
      onChange={handleChange}
      value={display_value}
      allowClear
    />
  );
};
