import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryStatCard } from 'app/shared/cards';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { useTransactionalService } from 'hooks/transactional';
import { useMailLogService } from 'hooks/maillog';
import { useMailActionService } from 'hooks/mailaction';
import { convertToPercent, getMonthNameFromIndex } from 'app/shared/utils/general';
import { useContactService } from 'hooks/contact';
import { DistributionChart } from 'app/shared/charts/distribution';
import { TimeSeriesChart, processDateRange } from 'app/shared/charts/timeseries';
import { Spacer } from 'app/layouts/generic';
import { ReportTable } from 'app/shared/datatable/simple';
import { getMonthTimestamps } from 'app/shared/utils/date';
import { useWalletLogService } from 'hooks/walletlogs';
import { toReadableNumber } from 'app/shared/utils/number';
import useScreenSize from 'hooks/size';
import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { toast } from 'react-toastify';

export const TransactionalsDashboardSection = ({ date_range = [] }) => {
  const start = new Date(date_range[0]).getTime();
  const end = new Date(date_range[1]).getTime() + 86400000 - 1;
  const { fetchMailLogs } = useMailLogService();
  const { fetchTransactionals } = useTransactionalService();
  const { fetchMailActions } = useMailActionService();
  const { fetchDistributionReports } = useContactService();
  const { fetchWalletLogs } = useWalletLogService();
  const { balance: wallet_balance } = useSelector((state) => state.wallet);

  const [transactionals, setTransactionals] = useState([]);
  const [selected_group, setSelectedGroup] = useState(0);
  const [is_loading_email_performance_data, setIsLoadingEmailPerformanceData] = useState(true);
  const [email_performance_data, setEmailPerformanceData] = useState({});
  const [is_loading_click_distribution, setIsLoadingClickDistribution] = useState(true);
  const [is_loading_open_distribution, setIsLoadingOpenDistribution] = useState(true);
  const [is_loading_monthly_report, setIsLoadingMonthlyReport] = useState(true);
  const [open_rate_distribution, setOpenDistribution] = useState([]);
  const [click_rate_distribution, setClickDistribution] = useState([]);
  const [monthly_stat, setMonthlyStat] = useState([]);
  const [transactional_count, setTransactionalCount] = useState({
    total_template: 0,
    total_sent: 0
  });
  const [link_clicks, setLinkClicks] = useState({
    total_clicks: 0,
    avg_click: 0
  });
  const [emails_opens, setEmailOpens] = useState({
    total_opens: 0,
    avg_opens: 0
  });
  const { isMobile } = useScreenSize();

  const chart_key_filters = [
    {
      label: 'Sends',
      value: 'sends',
      default: true,
      color: 'var(--blue-primary)'
    },
    {
      label: 'Bounces',
      value: 'bounces',
      color: 'var(--orange-primary)'
    },
    {
      label: 'Opens',
      value: 'opens',
      default: true,
      color: 'var(--neutral-dark-5)'
    }
  ];

  const report_columns = [
    {
      title: 'Month',
      key: 'month'
    },
    {
      title: 'Templates Created',
      key: 'total_templates'
    },
    {
      title: 'Emails Sent',
      key: 'total_sent'
    },
    {
      title: 'Total Opens',
      key: 'total_opens'
    },
    {
      title: 'Total Clicks',
      key: 'total_clicks'
    },
    {
      title: 'Total Bounces',
      key: 'total_bounces'
    },
    {
      title: 'Total Cost',
      key: 'total_cost'
    }
  ];

  useEffect(() => {
    fetchTransactionals({ query_string: `time_stamp=${start}~${end}&count=1` }).then(
      ({ error, size }) => {
        if (error) return toast.error(error);

        setTransactionalCount((curr_count) => ({
          ...curr_count,
          total_template: size
        }));
      }
    );

    fetchMailActions({
      query_string: `time_stamp=${start}~${end}&resource_type=transactional&action=clicked_link&count=1`
    }).then(({ mailactions: response, error, size }) => {
      if (error) return toast.error(error);
      setLinkClicks((curr_count) => ({
        ...curr_count,
        all_time: size
      }));
    });

    fetchMailLogs({
      query_string: `time_stamp=${start}~${end}&count=1&class=transactional&status=sent`
    }).then(({ size, error }) => {
      if (error) {
        toast.error(error);
        return;
      }
      const sends = size;

      setTransactionalCount((curr_count) => ({
        ...curr_count,
        total_sent: sends
      }));

      fetchMailActions({
        query_string: `time_stamp=${start}~${end}&resource_type=transactional&action=clicked_link&count=1`
      }).then(({ error, size }) => {
        if (error) return toast.error(error);

        setLinkClicks((curr_stats) => ({
          ...curr_stats,
          total_clicks: size,
          avg_click: convertToPercent(size, sends)
        }));
      });

      fetchMailActions({
        query_string: `time_stamp=${start}~${end}&resource_type=transactional&action=opened&count=1`
      }).then(({ error, size }) => {
        if (error) return toast.error(error);
        setEmailOpens((curr_stats) => ({
          ...curr_stats,
          total_opens: size,
          avg_opens: convertToPercent(size, sends)
        }));
      });
    });

    handleEmailPerformanceDateRangeChange();
  }, [start, end, date_range, selected_group]);

  useEffect(() => {
    fetchTransactionals({
      query_string: 'return_only=name,id'
    }).then(({ transactionals, error }) => {
      if (error) toast.error(error);

      const default_group = { label: 'All transactionals', value: 0 };
      const names = [default_group];

      transactionals.forEach((record) => {
        if (!record.name) return;
        names.push({
          label: record.name,
          value: record.id
        });
      });
      setTransactionals(() => names);
    });
  }, []);

  const handleEmailPerformanceDateRangeChange = async () => {
    const ranges = processDateRange(date_range[0], date_range[1]);
    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );
    setEmailPerformanceData(() => day_map);

    setIsLoadingEmailPerformanceData(true);
    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;

    for (let i = 0; i < ranges.length; i++) {
      const { start, end, label } = ranges[i];
      Promise.all([
        fetchMailLogs({
          query_string: `time_stamp=${start}~${end}&count=1&class=transactional${id_query}`
        }),
        fetchMailLogs({
          query_string: `time_stamp=${start}~${end}&count=1&class=transactional&status=bounced${id_query}`
        }),
        fetchMailActions({
          query_string: `time_stamp=${start}~${end}&resource_type=transactional&action=opened&count=1${id_query}`
        })
      ]).then(([{ size: sends }, { size: bounces }, { size: opens }]) => {
        setEmailPerformanceData((curr_data) => ({
          ...curr_data,
          [label]: {
            ...curr_data[label],
            sends: toReadableNumber(sends),
            bounces: toReadableNumber(bounces),
            opens: toReadableNumber(opens)
          }
        }));
      });
    }

    setIsLoadingEmailPerformanceData(false);
  };

  const handleOpenRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingOpenDistribution(() => true);

    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;
    const { mailactions, error } = await fetchMailActions({
      query_string: `return_only=recipient_email&resource_type=transactional&action=opened${id_query}`
    });
    if (error) return toast.error(error);
    const contact_ids = mailactions.map((action) => action.recipient_email);
    if (!contact_ids.length) {
      setIsLoadingOpenDistribution(() => false);
      return;
    }

    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_ids.join(), type: 'email' }
    });

    setIsLoadingOpenDistribution(() => false);
    setOpenDistribution(() => distribution);
  };

  const handleClickRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingClickDistribution(() => true);
    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;

    const { mailactions, error } = await fetchMailActions({
      query_string: `return_only=recipient_email&resource_type=transactional&action=clicked_link${id_query}`
    });
    if (error) return toast.error(error);

    const contact_ids = mailactions.map((action) => action.recipient_email);

    if (!contact_ids.length) {
      setIsLoadingClickDistribution(() => false);
      return;
    }

    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_ids.join(), type: 'email' }
    });

    setIsLoadingClickDistribution(() => false);
    setClickDistribution(() => distribution);
  };

  const handleGroupFilterChange = (group) => {
    setSelectedGroup(() => group);
  };

  const handleMonthlyReport = async (group) => {
    const { value: year } = group;
    const monthTimeStamps = getMonthTimestamps(year);
    if (!monthTimeStamps?.length) return;

    try {
      setIsLoadingMonthlyReport(true);
      const monthly_report = [];
      for (const { start, end } of monthTimeStamps) {
        const [
          { size: total_transactionals },
          { size: total_sent },
          { size: total_bounces },
          { size: total_opens },
          { size: total_clicks },
          { size: total_charges }
        ] = await Promise.all([
          fetchTransactionals({
            query_string: `time_stamp=${start}~${end}&count=1`
          }),
          fetchMailLogs({
            query_string: `time_stamp=${start}~${end}&class=transactional&count=1`
          }),
          fetchMailLogs({
            query_string: `time_stamp=${start}~${end}&class=transactional&status=bounced&count=1`
          }),
          fetchMailActions({
            query_string: `time_stamp=${start}~${end}&resource_type=transactional&action=opened&count=1`
          }),
          fetchMailActions({
            query_string: `time_stamp=${start}~${end}&resource_type=transactional&action=clicked_link&count=1`
          }),
          fetchWalletLogs({
            query_string: `time_stamp=${start}~${end}&resource_type=transactional&kind=charge&count=1`
          })
        ]);

        monthly_report.push({
          month: getMonthNameFromIndex(monthly_report.length),
          total_bounces: toReadableNumber(total_bounces),
          total_clicks: toReadableNumber(total_clicks),
          total_opens: toReadableNumber(total_opens),
          total_sent: toReadableNumber(total_sent),
          total_transactionals: toReadableNumber(total_transactionals),
          total_cost: toReadableNumber((total_charges * 0.4).toFixed(2))
        });
      }

      setMonthlyStat(monthly_report);
    } catch (error) {
      console.error('Error fetching mail data:', error);
    } finally {
      setIsLoadingMonthlyReport(false);
    }
  };

  const statDetails = [
    {
      main_stat: {
        label: 'Available credits',
        color: 'var(--neutral-light)',
        bg: 'var(--blue-primary)',
        label_color: 'var(--neutral-light)',
        value: wallet_balance
      },
      bottom_stat: {
        label: 'Charge rate:',
        value: 1
      }
    },
    {
      main_stat: { label: 'Total Transactionals', value: transactional_count.total_template },
      bottom_stat: { label: 'Total emails sent:', value: transactional_count.total_sent }
    },
    {
      main_stat: { label: 'Total emails Opens', value: emails_opens.total_opens },
      bottom_stat: { label: 'Avg. open rate', value: emails_opens.avg_opens }
    },
    {
      main_stat: { label: 'Total Link Clicks', value: link_clicks.total_clicks },
      bottom_stat: { label: 'Avg. click rate', value: link_clicks.avg_click }
    }
  ];

  return (
    <>
      <SlidingStatBar>
        {statDetails.map((stat, index) => (
          <PrimaryStatCard key={index} main_stat={stat.main_stat} bottom_stat={stat.bottom_stat} />
        ))}
      </SlidingStatBar>
      <Spacer multiple={4} />

      {!isMobile && (
        <>
          <GridRow>
            <GridColumn span={4}>
              <TimeSeriesChart
                showDatePicker={false}
                data={Object.values(email_performance_data)}
                filter_data={transactionals}
                key_filters={chart_key_filters}
                graph_title="Email Performance"
                is_loading_data={is_loading_email_performance_data}
                onDateRangeChange={handleEmailPerformanceDateRangeChange}
                onGroupChange={handleGroupFilterChange}
              />
            </GridColumn>
          </GridRow>
          <Spacer multiple={4} />
        </>
      )}

      <GridRow tabletStyles={{ gridTemplateColumns: 'repeat(2,1fr)' }}>
        <GridColumn span={2}>
          <DistributionChart
            title="Open rate distribution"
            data={open_rate_distribution}
            is_loading={is_loading_open_distribution}
            onChange={handleOpenRateDistribution}
          />
        </GridColumn>
        <GridColumn span={2}>
          <DistributionChart
            data={click_rate_distribution}
            is_loading={is_loading_click_distribution}
            title="Click rate distribution"
            onChange={handleClickRateDistribution}
          />
        </GridColumn>
      </GridRow>
      <Spacer multiple={4} />
      {!isMobile && (
        <GridRow num_of_columns={1}>
          <ReportTable
            title="Month on Month Report"
            columns={report_columns}
            data={monthly_stat}
            onChange={handleMonthlyReport}
            is_loading={is_loading_monthly_report}
          />
        </GridRow>
      )}
    </>
  );
};
