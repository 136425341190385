import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import * as ConfigService from 'services/mailing/configuration';

export const useMailingConfigService = () => {
  const { token } = useSelector((store) => store.user_data);

  const fetchConfiguration = async (options = {}) => {
    const { error, payload } = await ConfigService.read({ ...options, token });
    if (error) {
      return {
        configurations: [],
        size: 0,
        error: 'Unable to fetch email configuration at this time.'
      };
    }

    const {
      data: configurations,
      meta: { size }
    } = payload;
    return { configurations, size, error: null };
  };

  const updateConfiguration = async (options = {}) => {
    const { error, payload } = await ConfigService.update({
      data: { ...options },
      token
    });

    if (error) {
      toast.error('Unable to generate DNS records at this time. Please try again.');
      return null;
    }

    toast.success('DNS records generated successfully.');
    return payload;
  };

  return { fetchConfiguration, updateConfiguration };
};
