import { Spacer } from 'app/layouts/generic';
import React from 'react';
import styles from './code.module.css';

export const CodeWrapper = ({ info_text, text, onClick }) => {
  return (
    <>
      <div className={styles.codeWrapper} onClick={() => onClick(text)}>
        <code className={styles.code}>{text}</code>
      </div>
      <Spacer multiple={1} />
      <span className={styles.infoText}>{info_text}</span>
    </>
  );
};
