/** */
import { urls } from '../_url';
import { apiGet, apiPut } from '../_http';

/** */
const base_url = `${urls.mailing}/configurations`;

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const update = async (options) => {
  return await apiPut(`${base_url}`, { ...options });
};
