import React from 'react';
import styles from './input.module.css';

export const TextArea = ({
  error = '',
  label = '',
  input_id = '',
  className = '',
  disabled,
  placeholder = '',
  value = '',
  rows = 3,
  onInput = () => {},
  style = {}
}) => {
  return (
    <div className={styles.formGroup}>
      <label htmlFor={input_id}>{label}</label>
      <textarea
        rows={rows}
        placeholder={placeholder}
        value={value}
        onChange={() => {}}
        onInput={(e) => onInput(e.target.value)}
        className={className}
        style={style}
        id={input_id}
        disabled={disabled}
      ></textarea>
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};
