import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { WebsiteIntegration } from './items/website';
import useScreenSize from 'hooks/size';

const SettingsModule = (props) => {
  const dispatch = useDispatch();
  const { isTablet } = useScreenSize();

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Integrations', path: '.' }]));
  }, [dispatch]);

  return (
    <PageContentWrapper>
      <GridRow num_of_columns={isTablet ? 2 : 3}>
        <GridColumn>
          <WebsiteIntegration />
        </GridColumn>
      </GridRow>
    </PageContentWrapper>
  );
};

export default SettingsModule;
