import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { addOneEventToStore, updateEventInStore } from 'store/actions/event';
import { createEventValidationConfig } from 'app/shared/utils/form/validation/event';
import cx from 'classnames';

import styles from './event.module.css';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import useValidator from 'hooks/validator';
import { useEventsService } from 'hooks/automation/event';
import { GridColumn, GridRow } from 'app/layouts/grid';
import useScreenSize from 'hooks/size';
import { PermissionsContext } from 'contexts/permissions';

export const CreateEventForm = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigateTo = useNavigate();
  const { isTablet } = useScreenSize();
  const { permissions } = useContext(PermissionsContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: '',
    code: '',
    resource_class: '',
    in_use: false
  });
  const { errors, validateField, form_is_valid } = useValidator(createEventValidationConfig);
  const { createEvent, fetchEvent, updateEvent } = useEventsService();

  useEffect(() => {
    if (id) {
      fetchEvent(id, { query_string: 'type=user' }).then(async (response) => {
        const { code, name, resource_class, in_use } = response;
        const updated_code = code.split(':')[0];
        setData((previousValues) => ({
          ...previousValues,
          code: updated_code,
          name,
          resource_class,
          in_use
        }));
      });
    }
  }, [id]);

  const clearForm = () => {
    setData({
      name: '',
      code: ''
    });
    if (id) navigateTo('/settings/events');
  };

  const submitForm = async () => {
    try {
      setLoading(true);
      if (!id) {
        const payload = await createEvent({
          data: {
            ...data,
            resource_class: 'custom'
          }
        });

        if (!payload) return;
        toast.success('Event created successfully.');
        dispatch(addOneEventToStore({ ...payload, code: data.code }));
        clearForm();
      } else {
        const payload = await updateEvent(id, { data });
        if (!payload) return;
        const values = { ...data, code: `${data.code}`, id };
        dispatch(updateEventInStore(values));
        toast.success('Event updated successfully.');
        clearForm();
      }
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event;
    const code_in_use = id && data.in_use;

    if (name === 'name') {
      const lowercaseValue = value.toLowerCase().replace(/ /g, '_');
      setData((prevData) => ({
        ...prevData,
        name: value,
        code: code_in_use ? prevData.code : lowercaseValue
      }));
      validateField(name, value);
      validateField('code', lowercaseValue);
    } else {
      let processedValue = value;
      if (name === 'code' && code_in_use) return;
      if (name === 'code') {
        processedValue = value.toLowerCase().replace(/ /g, '_');
      }

      setData((prevData) => ({
        ...prevData,
        [name]: processedValue
      }));

      validateField(name, processedValue);
    }
  };

  return (
    <BasicInfoSection title={!id ? 'Create custom event' : 'Update custom event'}>
      <GridRow num_of_columns={isTablet ? 2 : 1}>
        <GridColumn>
          <DetailItem title="Name">
            <SimpleInput
              input_id="name"
              name="name"
              value={data.name}
              onInput={handleChange}
              error={errors.name}
              required
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Code">
            <SimpleInput
              input_id="code"
              name="code"
              value={data.code}
              onInput={handleChange}
              error={errors.code}
              required
            />
          </DetailItem>
        </GridColumn>
        {isTablet && <GridColumn />}
        <GridColumn>
          <div className={cx('grid mt-2', id ? styles.action : '')}>
            {id && <Button type="secondary" onClick={clearForm} text="Clear" />}
            <Button
              onClick={submitForm}
              text="Save"
              className="float-right"
              loading={loading}
              disabled={
                !form_is_valid || loading || !id
                  ? !permissions['automation_event:create']
                  : !permissions['automation_event:update']
              }
            />
          </div>
        </GridColumn>
      </GridRow>
    </BasicInfoSection>
  );
};
