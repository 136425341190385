import { GridColumn, GridRow } from 'app/layouts/grid';
import { useMailingConfigService } from 'hooks/mailing/configuration';
import React, { useEffect, useState } from 'react';
import { DKIMConfigurationDetails } from './dkim';
import { DKIMConfigurationEditor } from './dkim/edit';
import { toast } from 'react-toastify';
import { setPageTitle } from 'store/actions/header';
import { useDispatch } from 'react-redux';

export const EmailSettings = () => {
  const dispatch = useDispatch();
  const { fetchConfiguration } = useMailingConfigService();

  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchConfig = () => {
    setLoading(true);
    fetchConfiguration()
      .then(({ configurations, error }) => {
        if (error) toast.error(error);
        setConfig(configurations[0] || {});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    dispatch(
      setPageTitle([
        { title: 'Settings', path: '/settings' },
        { title: 'Email', path: '/settings/email' }
      ])
    );

    fetchConfig();
  }, []);

  return (
    <GridRow>
      <GridColumn />
      <GridColumn span={2}>
        {config?.dkim || config?.spf ? (
          <DKIMConfigurationDetails email_config={config} />
        ) : (
          <DKIMConfigurationEditor email_config={config} loading={loading} onSave={fetchConfig} />
        )}
      </GridColumn>
      <GridColumn />
    </GridRow>
  );
};
