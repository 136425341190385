import { Spacer } from 'app/layouts/generic';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { Button } from 'app/shared/button';
import { DetailItem } from 'app/shared/info-section/item';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { createContactValidationConfig } from 'app/shared/utils/form/validation/contact';
import { useAudienceService } from 'hooks/users/audience';
import { useContactService } from 'hooks/contact';
import useScreenSize from 'hooks/size';
import useValidator from 'hooks/validator';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { addOneContactToStore } from 'store/actions/contact';
import styles from './create.module.css';
import { toast } from 'react-toastify';

const gender_options = [
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'female',
    label: 'Female'
  },
  {
    value: 'other',
    label: 'Other'
  }
];

export const CreateContactModal = ({ closeModal }) => {
  const dispatch = useDispatch();
  const { isMobile } = useScreenSize();
  const { createContact } = useContactService();
  const { addContacts, fetchAudiences } = useAudienceService();

  const [form_data, setFormData] = useState({});
  const [gender, setGender] = useState({});
  const [audiences, setAudiences] = useState([]);
  const [selected_audiences, setSelectedAudiences] = useState([]);
  const [is_saving, setSaving] = useState(false);

  const { errors, validateField, form_is_valid } = useValidator(createContactValidationConfig);

  useEffect(() => {
    fetchAudiences().then(({ audiences, error }) => {
      if (error) {
        toast.error('Unable to fetch audience at this time.');
        return;
      }
      if (!audiences.length) return;
      setAudiences(() =>
        audiences.map((audience) => ({ label: audience.name, value: audience.id }))
      );
    });
  }, []);

  const handleClose = () => {
    resetForm();
    closeModal();
  };

  const handleSimpleInputChange = (key, value = '') => {
    validateField(key, value);
    setFormData((curr_data) => ({
      ...curr_data,
      [key]: value
    }));
  };

  const handleSubmit = async () => {
    const data = {
      ...form_data,
      source: 'in-app'
    };

    setSaving(() => true);
    const contact_data = await createContact({ data });
    if (contact_data) {
      dispatch(addOneContactToStore(contact_data));
      addContacts(
        selected_audiences.map((audience) => audience.value),
        [contact_data.id]
      );
      handleClose();
    }
    setSaving(() => false);
  };

  const resetForm = () => {
    setFormData(() => ({}));
    setSelectedAudiences(() => []);
    setGender(() => []);
  };

  const handleAudienceSelection = (audiences) => {
    setSelectedAudiences(audiences);
    const selections = audiences?.map((audience) => audience.value) || [];
    handleSimpleInputChange('mailing_lists', selections);
  };

  const handleGenderSelection = (value) => {
    setGender(value);
    handleSimpleInputChange('gender', value.value);
  };

  return (
    <div className={styles.wrapper}>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="First name">
            <SimpleInput
              value={form_data.firstname}
              onInput={(v) => handleSimpleInputChange('firstname', v)}
              error={errors.firstname}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Last name">
            <SimpleInput
              value={form_data.lastname}
              onInput={(v) => handleSimpleInputChange('lastname', v)}
              error={errors.lastname}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn span={2}>
          <DetailItem title="Email address">
            <SimpleInput
              value={form_data.email}
              onInput={(v) => handleSimpleInputChange('email', v)}
              error={errors.email}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn span={2}>
          <DetailItem title="Audiences">
            <MultiSelect
              options={audiences}
              value={selected_audiences}
              onChange={handleAudienceSelection}
              isMulti
              error={errors.audiences}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <GridRow num_of_columns={2}>
        <GridColumn>
          <DetailItem title="Gender">
            <MultiSelect
              options={gender_options}
              value={gender}
              onChange={handleGenderSelection}
              error={errors.gender}
            />
          </DetailItem>
        </GridColumn>
        <GridColumn>
          <DetailItem title="Date of birth">
            <SimpleInput
              type="date"
              value={form_data.date_of_birth}
              max={new Date().toISOString().split('T')[0]}
              onInput={(v) => handleSimpleInputChange('date_of_birth', v)}
              error={errors.date_of_birth}
            />
          </DetailItem>
        </GridColumn>
      </GridRow>
      <Spacer multiple={3} />
      <GridRow num_of_columns={2}>
        <GridColumn></GridColumn>
        <GridColumn>
          <GridRow num_of_columns={2}>
            {!isMobile && (
              <GridColumn>
                <Button text="Cancel" type="secondary" onClick={handleClose} />
              </GridColumn>
            )}
            <GridColumn>
              <Button text="Save" disabled={!form_is_valid || is_saving} onClick={handleSubmit} />
            </GridColumn>
          </GridRow>
        </GridColumn>
      </GridRow>
    </div>
  );
};
