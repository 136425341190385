import { Spacer } from 'app/layouts/generic';
import { MultiSelect } from 'app/shared/select';
import { DistributionSkeleton } from 'app/shared/skeleton';
import React, { useEffect, useState } from 'react';
import styles from './distribution.module.css';

const filters = [
  {
    label: 'Age',
    value: 'age'
  },
  {
    label: 'Gender',
    value: 'gender'
  },
  {
    label: 'Email domain',
    value: 'domain'
  }
];

export const DistributionItem = ({ name, value, total }) => {
  const [style, setStyle] = useState({});
  useEffect(() => {
    const width = total ? (value / total) * 100 : 0;
    setStyle(() => generateStyle(width));
  }, [total, value]);

  const determineColors = (width) => {
    if (width > 50) return 'var(--green-primary)';
    if (width > 30) return 'var(--blue-light-1)';
    return 'var(--blue-light-3)';
  };

  const generateStyle = (width) => {
    const backgroundColor = determineColors(width);
    return {
      color: 'var(--blue-dark)',
      height: '100%',
      width: `${width}%`,
      backgroundColor
    };
  };

  return (
    <div className={styles.item}>
      <div className={styles.itemTitle}>{name}</div>
      <div className={styles.valueBar}>
        <div style={style}></div>
      </div>
    </div>
  );
};

export const DistributionChart = ({
  title = 'Distribution Chart',
  data = [],
  is_loading = true,
  onChange = () => {}
}) => {
  const [total_value, setTotalValue] = useState(0);
  const [processed_data, setProcessedData] = useState([]);
  const [selected_filter, setSelectedFilter] = useState({});

  useEffect(() => {
    handleFilterChange(filters[0]);
  }, []);

  useEffect(() => {
    setTotalValue(() => data.reduce((sum, datum) => (sum += datum.value), 0));
    if (selected_filter.value === 'domain') {
      setProcessedData(() => processLengthyDistributions(data));
      return;
    }

    setProcessedData(data);
  }, [data]);

  const handleFilterChange = (selection = {}) => {
    onChange(selection);
    setSelectedFilter(() => selection);
  };

  const processLengthyDistributions = (distributions = []) => {
    const others = { name: 'others', value: 0 };
    const sorted_distros = distributions.sort((a, b) => b.value - a.value);
    sorted_distros.slice(5).forEach(({ value }) => (others.value += value));
    const result = [...sorted_distros.slice(0, 5), others];
    return result.sort((a, b) => b.value - a.value);
  };

  return (
    <div className={styles.wrapper}>
      <header>
        <div className={styles.title}>{title}</div>
        <div className={styles.filters}>
          <MultiSelect options={filters} value={selected_filter} onChange={handleFilterChange} />
        </div>
      </header>
      <Spacer multiple={4} />
      {is_loading ? (
        <DistributionSkeleton />
      ) : !is_loading && !processed_data.length ? (
        <section className={styles.noData}>No data available</section>
      ) : (
        <section>
          {processed_data.map(({ name, value }) => (
            <DistributionItem key={name} name={name} value={value} total={total_value} />
          ))}
        </section>
      )}
    </div>
  );
};
