/** */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { set_process } from 'store/actions/process';
import { Button } from '../button';
import { checkDNSStatus } from '../utils/general';
import { useMailboxService } from 'hooks/users/mailbox';

const DNSMessage = () => (
  <div className="alert alert-danger">
    You are attempting to verify an email for a new domain. Please ensure to add the following SPF
    record to your domain settings. If you need help, please contact support@go-mailer.com for
    assistance:
    <br />
    <br />
    <code>TXT @ IN "v=spf1 include:mail.go-mailer.com ~all"</code>
  </div>
);

const VerifySenderEmail = ({ email, mailbox, closeModal }) => {
  const { verifyMailbox } = useMailboxService();

  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const submit = async () => {
    try {
      setLoading(true);
      const result = await verifyMailbox(mailbox.id, { data: { code, email } });
      if (!result) return;
      dispatch(set_process('email_verification', true));
      setCode('');
      closeModal();
    } finally {
      setLoading(false);
    }
  };

  const autoFocusField = (e) => {
    if (e != null) e.focus();
  };

  return (
    <div>
      {checkDNSStatus(mailbox.emails, email) && <DNSMessage />}
      <div className="alert alert-info">Enter verification code sent to {email}</div>
      <div className="form-row">
        <label htmlFor="verification_code">Verification Code:</label>
        <input
          ref={autoFocusField}
          type="text"
          className="gm-input"
          id="verification_code"
          value={code}
          onInput={(e) => setCode(e.target.value)}
          onChange={(f) => f}
        />
      </div>
      <div className="mt-3 mb-3 form-row">
        <div className="col-md-8"></div>
        <div className="col-md-4 pr-md-0 px-sm-0">
          <Button text="Verify" onClick={submit} loading={loading} />
        </div>
      </div>
    </div>
  );
};

export default VerifySenderEmail;
