import {
  EFFECT_ADD_CONTACT_TAG,
  EFFECT_SEND_TRANSACTIONAL,
  EFFECT_SUBSCRIBE,
  EFFECT_UNSUBSCRIBE,
  EVENT_BIRTHDAY,
  EVENT_CLICKED_LINK,
  EVENT_NEW_CONTACT,
  EVENT_OPENED_CAMPAIGN,
  EVENT_OPENED_TRANSACTIONAL,
  EVENT_SUBSCRIPTION,
  EVENT_UNSUBSCRIPTION
} from '@go-mailer/jarvis/lib/constants/automation';

export const serializeAction = (action, title) => {
  const [event] = action.split(':');
  switch (event) {
    case EVENT_BIRTHDAY:
      return (
        <span>
          marks their <b>Birthday</b>
        </span>
      );
    case EVENT_CLICKED_LINK:
      return (
        <span>
          clicks the link <b>{title}</b>
        </span>
      );
    case EVENT_NEW_CONTACT:
      return <span>is newly created</span>;
    case EVENT_OPENED_CAMPAIGN:
      return (
        <span>
          opens the campaign <b>{title}</b>
        </span>
      );
    case EVENT_OPENED_TRANSACTIONAL:
      return (
        <span>
          opens the transactional <b>{title}</b>
        </span>
      );
    case EVENT_SUBSCRIPTION:
      return (
        <span>
          is added to the audience <b>{title}</b>
        </span>
      );
    case EVENT_UNSUBSCRIPTION:
      return (
        <span>
          leaves to the audience <b>{title}</b>
        </span>
      );
    case EFFECT_ADD_CONTACT_TAG:
      return (
        <span>
          Add{' '}
          <b>
            <em>{title}</em>
          </b>{' '}
          tag to contact
        </span>
      );
    case EFFECT_SEND_TRANSACTIONAL:
      return (
        <span>
          Send transactional <b>{title}</b> to the contact
        </span>
      );
    case EFFECT_SUBSCRIBE:
      return (
        <span>
          Add contact to audience <b>{title}</b>
        </span>
      );
    case EFFECT_UNSUBSCRIBE:
      return (
        <span>
          Remove contact from audience <b>{title}</b>
        </span>
      );
    default:
      return (
        <span>
          triggers custom event <b>{event}</b>
        </span>
      );
  }
};

export const serializeDelay = (rule) => {
  const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const { interval, interval_type, weekday } = rule;

  switch (interval_type) {
    case 'weekday':
      return DAYS[weekday];
    default:
      return `${interval || 0} ${interval_type === 'day' ? 'Days' : 'Hours'}`;
  }
};
