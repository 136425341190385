/** */
const { REACT_APP_ENV } = process.env;

const app_config = {
  development: {
    landing: 'http://localhost:3001',
    editor: 'http://localhost:3003',
    files: 'http://localhost:4010',
    iam: 'http://localhost:4011',
    mailing: 'http://localhost:4002',
    sales: 'http://localhost:4003',
    users: 'http://localhost:4001',
    notification: 'http://localhost:4007',
    automata: 'http://localhost:4009',
    terms: 'http://localhost:3001/terms',
    policy: 'http://localhost:3001/privacy'
  },
  testing: {
    landing: 'https://gm-g6.xyz',
    editor: 'https://editor.gm-g6.xyz',
    files: 'https://files.gm-g6.xyz',
    iam: 'https://iam.gm-g6.xyz',
    mailing: 'https://mailing.gm-g6.xyz',
    sales: 'https://sales.gm-g6.xyz',
    users: 'https://users.gm-g6.xyz',
    notification: 'https://notification.gm-g6.xyz',
    automata: 'https://automata.gm-g6.xyz',
    terms: 'https://gm-g6.xyz/terms',
    policy: 'https://gm-g6.xyz/privacy'
  },
  staging: {
    landing: 'https://gm-g7.xyz',
    editor: 'https://editor.gm-g7.xyz',
    files: 'https://files.gm-g7.xyz',
    iam: 'https://iam.gm-g7.xyz',
    mailing: 'https://mailing.gm-g7.xyz',
    sales: 'https://sales.gm-g7.xyz',
    users: 'https://users.gm-g7.xyz',
    notification: 'https://notification.gm-g7.xyz',
    automata: 'https://automata.gm-g7.xyz',
    terms: 'https://gm-g7.xyz/terms',
    policy: 'https://gm-g7.xyz/privacy'
  },
  production: {
    landing: 'https://go-mailer.com',
    editor: 'https://editor.go-mailer.com',
    files: 'https://files.go-mailer.com',
    iam: 'https://iam.go-mailer.com',
    mailing: 'https://mailing.go-mailer.com',
    sales: 'https://sales.go-mailer.com',
    users: 'https://users.go-mailer.com',
    notification: 'https://notification.go-mailer.com',
    automata: 'https://automata.go-mailer.com',
    terms: 'https://go-mailer.com/terms',
    policy: 'https://go-mailer.com/privacy'
  }
};

export const urls = { ...app_config[REACT_APP_ENV || 'development'] };
