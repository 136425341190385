import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PrimaryStatCard } from 'app/shared/cards';
import { useCampaignService } from 'hooks/mailing/campaign';
import { useContactService } from 'hooks/contact';
import { useWalletLogService } from 'hooks/walletlogs';
import { useMailLogService } from 'hooks/maillog';
import { useMailActionService } from 'hooks/mailaction';
import { GridColumn, GridRow } from 'app/layouts/grid';
import { convertToPercent, getMonthNameFromIndex } from 'app/shared/utils/general';
import { Spacer } from 'app/layouts/generic';
import { TimeSeriesChart, processDateRange } from 'app/shared/charts/timeseries';
import { DistributionChart } from 'app/shared/charts/distribution';
import { ReportTable } from 'app/shared/datatable/simple';
import useScreenSize from 'hooks/size';
import { SlidingStatBar } from 'app/layouts/stats/sliding';
import { toast } from 'react-toastify';
import { getMonthTimestamps } from 'app/shared/utils/date';
import { toReadableNumber } from 'app/shared/utils/number';

export const CampaignsDashboardSection = ({ date_range = [] }) => {
  const start = new Date(date_range[0]).getTime();
  const end = new Date(date_range[1]).getTime() + 86400000 - 1;
  const { fetchCampaigns } = useCampaignService();
  const { fetchWalletLogs } = useWalletLogService();
  const { fetchMailLogs } = useMailLogService();
  const { fetchMailActions } = useMailActionService();
  const { fetchDistributionReports } = useContactService();
  const { isMobile } = useScreenSize();
  const { balance: wallet_balance } = useSelector((state) => state.wallet);

  const [basic_stats, setBasicStats] = useState({});
  const [campaigns, setCampaigns] = useState([]);
  const [selected_group, setSelectedGroup] = useState(0);
  const [is_loading_click_distribution, setIsLoadingClickDistribution] = useState(true);
  const [is_loading_email_performance_data, setIsLoadingEmailPerformanceData] = useState(true);
  const [is_loading_open_distribution, setIsLoadingOpenDistribution] = useState(true);
  const [is_loading_monthly_report, setIsLoadingMonthlyReport] = useState(true);
  const [click_rate_distribution, setClickDistribution] = useState([]);
  const [email_performance_data, setEmailPerformanceData] = useState({});
  const [open_rate_distribution, setOpenDistribution] = useState([]);
  const [monthly_stat, setMonthlyStat] = useState([]);
  const [campaign_count, setCampaignCount] = useState({
    all_time: 0,
    avg_spent: 0
  });

  const chart_key_filters = [
    {
      label: 'Sends',
      value: 'sends',
      default: true,
      color: 'var(--blue-primary)'
    },
    {
      label: 'Bounces',
      value: 'bounces',
      color: 'var(--orange-primary)'
    },
    {
      label: 'Opens',
      value: 'opens',
      default: true,
      color: 'var(--neutral-dark-5)'
    }
  ];

  const report_columns = [
    {
      title: 'Month',
      key: 'month'
    },
    {
      title: 'Campaigns Sent',
      key: 'total_campaigns'
    },
    {
      title: 'Emails Sent',
      key: 'total_sent'
    },
    {
      title: 'Total Opens',
      key: 'total_opens'
    },
    {
      title: 'Total Clicks',
      key: 'total_clicks'
    },
    {
      title: 'Total Bounces',
      key: 'total_bounces'
    },
    {
      title: 'Total Cost',
      key: 'total_cost'
    }
  ];

  useEffect(() => {
    fetchCampaigns({
      query_string: `status=processed&time_stamp=${start}~${end}&return_only=id&bool=-is_test_campaign`
    }).then(({ campaigns, size, error }) => {
      if (error) {
        toast.error('Unable to fetch campaigns at this time.');
        return;
      }
      if (size === 0) return;

      const campaign_ids = campaigns.map((campaign) => campaign.id);
      setCampaignCount((curr_count) => ({
        ...curr_count,
        all_time: size
      }));

      fetchWalletLogs({
        query_string: `resource_type=campaign&resource_id=${campaign_ids.join()}&return_only=kind,amount`
      }).then(({ walletlogs, error }) => {
        if (error) toast.error(error);

        let count = 0;
        const cost = walletlogs.reduce((total, log) => {
          if (log.kind === 'charge') {
            count++;
            return (total += Number(log.amount));
          }
          count--;
          return (total -= Number(log.amount));
        }, 0);
        setCampaignCount((curr_count) => ({
          ...curr_count,
          avg_spent: cost / count
        }));
      });
    });

    fetchMailLogs({
      query_string: `time_stamp=${start}~${end}&count=1&class=campaign&status=sent`
    }).then(({ size, error }) => {
      if (error) {
        toast.error(error);
        return;
      }
      const sends = size;
      fetchMailActions({
        query_string: `time_stamp=${start}~${end}&resource_type=campaign&action=opened&count=1`
      }).then(({ size, error }) => {
        if (error) return toast.error(error);

        setBasicStats((curr_stats) => ({
          ...curr_stats,
          opens: size,
          avg_open_rate: convertToPercent(size, sends)
        }));
      });

      fetchMailActions({
        query_string: `time_stamp=${start}~${end}&resource_type=campaign&action=clicked_link&count=1`
      }).then(({ size }) => {
        if (error) return toast.error(error);
        setBasicStats((curr_stats) => ({
          ...curr_stats,
          link_clicks: size,
          click_rate: convertToPercent(size, sends)
        }));
      });
    });

    handleEmailPerformanceDateRangeChange();
  }, [start, end, date_range, selected_group]);

  useEffect(() => {
    fetchCampaigns({
      query_string: 'return_only=name,id'
    }).then(({ campaigns, error }) => {
      if (error) {
        toast.error('Unable to fetch campaigns at this time.');
        return;
      }
      const default_group = { label: '- All campaigns', value: 0 };
      const names = [default_group];

      campaigns.forEach((record) => {
        if (!record.name) return;
        names.push({
          label: record.name,
          value: record.id
        });
      });
      setCampaigns(() => names);
    });
  }, []);

  const handleEmailPerformanceDateRangeChange = async () => {
    const ranges = processDateRange(date_range[0], date_range[1]);
    const day_map = ranges.reduce(
      (sac, { label }) => ({
        ...sac,
        [label]: { day: label }
      }),
      {}
    );
    setEmailPerformanceData(() => day_map);

    setIsLoadingEmailPerformanceData(true);
    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;

    for (let i = 0; i < ranges.length; i++) {
      const { start, end, label } = ranges[i];
      await Promise.all([
        fetchMailLogs({
          query_string: `time_stamp=${start}~${end}&count=1&class=campaign${id_query}`
        }),
        fetchMailLogs({
          query_string: `time_stamp=${start}~${end}&count=1&class=campaign&status=bounced${id_query}`
        }),
        fetchMailActions({
          query_string: `time_stamp=${start}~${end}&resource_type=campaign&action=opened&count=1${id_query}`
        })
      ]).then(([{ size: sends }, { size: bounces }, { size: opens }]) => {
        setEmailPerformanceData((curr_data) => ({
          ...curr_data,
          [label]: {
            ...curr_data[label],
            sends: toReadableNumber(sends),
            bounces: toReadableNumber(bounces),
            opens: toReadableNumber(opens)
          }
        }));
      });
    }
    setIsLoadingEmailPerformanceData(false);
  };

  const handleOpenRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingOpenDistribution(() => true);

    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;
    const { mailactions, error } = await fetchMailActions({
      query_string: `return_only=recipient_id&resource_type=campaign&action=opened${id_query}`
    });
    if (error) return toast.error(error);

    const contact_ids = mailactions.map((action) => action.recipient_id);

    if (!contact_ids.length) {
      setIsLoadingOpenDistribution(() => false);
      return;
    }

    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_ids.join() }
    });

    setIsLoadingOpenDistribution(() => false);
    setOpenDistribution(() => distribution);
  };

  const handleClickRateDistribution = async (group) => {
    const { value: category } = group;
    setIsLoadingClickDistribution(() => true);
    const id_query = selected_group <= 0 ? '' : `&resource_id=${selected_group}`;

    const { mailactions, error } = await fetchMailActions({
      query_string: `return_only=recipient_email&resource_type=campaign&action=clicked_link${id_query}`
    });
    if (error) return toast.error(error);

    const contact_emails = mailactions.map((action) => action.recipient_email);
    if (!contact_emails.length) {
      setIsLoadingClickDistribution(() => false);
      return;
    }
    const { distribution } = await fetchDistributionReports({
      query_string: `category=${category}`,
      data: { ids: contact_emails.join(), type: 'email' }
    });

    setIsLoadingClickDistribution(() => false);
    setClickDistribution(() => distribution);
  };

  const handleGroupFilterChange = (group) => {
    setSelectedGroup(() => group);
  };

  const handleMonthlyReport = async (group) => {
    const { value: year } = group;
    const monthTimeStamps = getMonthTimestamps(year);
    if (!monthTimeStamps?.length) return;

    try {
      setIsLoadingMonthlyReport(true);
      const monthly_report = [];

      for (const { start, end } of monthTimeStamps) {
        const {
          campaigns,
          size: total_campaigns,
          error
        } = await fetchCampaigns({
          query_string: `time_stamp=${start}~${end}&status=processed&return_only=is_test_campaign,id`
        });
        if (error) toast.error(error);

        const test_campaign_ids = campaigns.filter((c) => c.is_test_campaign).map((c) => c.id);
        const exclusion_query = `resource_id=${test_campaign_ids.join('!')}`;
        const [
          { size: total_sent },
          { size: total_bounces },
          { size: total_opens },
          { size: total_clicks },
          { walletlogs }
        ] = await Promise.all([
          fetchMailLogs({
            query_string: `time_stamp=${start}~${end}&class=campaign&${exclusion_query}&count=1`
          }),
          fetchMailLogs({
            query_string: `time_stamp=${start}~${end}&class=campaign&${exclusion_query}&status=bounced&count=1`
          }),
          fetchMailActions({
            query_string: `time_stamp=${start}~${end}&resource_type=campaign&${exclusion_query}&action=opened&count=1`
          }),
          fetchMailActions({
            query_string: `time_stamp=${start}~${end}&resource_type=campaign&${exclusion_query}&action=clicked_link&count=1`
          }),
          fetchWalletLogs({
            query_string: `time_stamp=${start}~${end}&resource_type=campaign&kind=charge&return_only=amount`
          })
        ]).catch(() => toast.error('Unable to fetch logs at this time.'));

        monthly_report.push({
          month: getMonthNameFromIndex(monthly_report.length),
          total_bounces: toReadableNumber(total_bounces),
          total_clicks: toReadableNumber(total_clicks),
          total_opens: toReadableNumber(total_opens),
          total_sent: toReadableNumber(total_sent),
          total_campaigns: toReadableNumber(total_campaigns - test_campaign_ids.length),
          total_cost: toReadableNumber(walletlogs.reduce((s, log) => s + log.amount, 0))
        });
      }

      setMonthlyStat(monthly_report);
    } catch (error) {
      console.error('Error fetching mail data:', error);
    } finally {
      setIsLoadingMonthlyReport(false);
    }
  };

  const statDetails = [
    {
      main_stat: {
        label: 'Available credits',
        color: 'var(--neutral-light)',
        bg: 'var(--blue-primary)',
        label_color: 'var(--neutral-light)',
        value: wallet_balance
      },
      bottom_stat: {
        label: 'Charge rate:',
        value: 1
      }
    },
    {
      main_stat: { label: 'Total Campaigns', value: campaign_count.all_time },
      bottom_stat: { label: 'Avg. spend', value: campaign_count.avg_spent }
    },
    {
      main_stat: { label: 'Total emails Opens', value: basic_stats.opens },
      bottom_stat: { label: 'Avg. open rate', value: basic_stats.avg_open_rate }
    },
    {
      main_stat: {
        label: 'Total link clicks',
        color: 'var(--blue-primary)',
        value: basic_stats.link_clicks
      },
      bottom_stat: {
        label: 'Avg. click rate (%)',
        value: basic_stats.click_rate
      }
    }
  ];

  return (
    <>
      <SlidingStatBar>
        {statDetails.map((stat, index) => (
          <PrimaryStatCard
            key={index}
            main_stat={stat.main_stat}
            bottom_stat={stat.bottom_stat}
            wrapperStyle={{ marginRight: '15px' }}
          />
        ))}
      </SlidingStatBar>
      <Spacer multiple={4} />

      {!isMobile && (
        <>
          <GridRow>
            <GridColumn span={4}>
              <TimeSeriesChart
                showDatePicker={false}
                data={Object.values(email_performance_data)}
                filter_data={campaigns}
                key_filters={chart_key_filters}
                graph_title="Email Performance"
                is_loading_data={is_loading_email_performance_data}
                onDateRangeChange={handleEmailPerformanceDateRangeChange}
                onGroupChange={handleGroupFilterChange}
              />
            </GridColumn>
          </GridRow>
          <Spacer multiple={4} />
        </>
      )}
      <GridRow tabletStyles={{ gridTemplateColumns: 'repeat(2,1fr)' }}>
        <GridColumn span={2}>
          <DistributionChart
            title="Open rate distribution"
            data={open_rate_distribution}
            is_loading={is_loading_open_distribution}
            onChange={handleOpenRateDistribution}
          />
        </GridColumn>
        <GridColumn span={2}>
          <DistributionChart
            data={click_rate_distribution}
            is_loading={is_loading_click_distribution}
            title="Click rate distribution"
            onChange={handleClickRateDistribution}
          />
        </GridColumn>
      </GridRow>
      {!isMobile && (
        <>
          <Spacer multiple={4} />
          <GridRow num_of_columns={1}>
            <ReportTable
              title="Month on Month Report"
              columns={report_columns}
              data={monthly_stat}
              onChange={handleMonthlyReport}
              is_loading={is_loading_monthly_report}
            />
          </GridRow>
        </>
      )}
    </>
  );
};
