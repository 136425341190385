/** */
import cx from 'classnames';
import { Icon } from 'assets/icons';
import React, { useEffect, createRef, useState } from 'react';
import styles from './modal.module.css';

const GmModal = ({
  children,
  className,
  bodyClassName,
  title,
  show_header = true,
  show_title,
  show_modal,
  onClose
}) => {
  /** REFS */
  const modal_veil = createRef();
  const modal_body = createRef();
  const [veil, setVeil] = useState();

  /** */
  useEffect(() => {
    if (show_modal) {
      fadeIn();
    } else if (veil) {
      closeModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show_modal]);

  useEffect(() => {
    setVeil(modal_veil.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal_veil]);

  const closeModal = () => {
    veil.click();
  };

  const fadeIn = () => {
    if (!modal_veil || !modal_veil.current) return;
    const veil = modal_veil.current;
    let opacity = 0;
    const body = modal_body.current;

    veil.style.display = 'flex';
    body.style.opacity = 0;
    const fade_in_animation = setInterval(() => {
      if (opacity > 0.4) {
        body.style.opacity = 1;
        clearInterval(fade_in_animation);
        return;
      }

      opacity += 0.1;
      veil.style.background = `rgba(0,0,0, ${opacity})`;
      body.style.opacity = opacity;
    }, 65);
  };

  const fadeOut = () => {
    if (!modal_veil || !modal_veil.current) return;

    const veil = modal_veil.current;
    let opacity = 0.4;
    const body = modal_body.current;

    const fade_out_animation = setInterval(() => {
      if (opacity <= 0) {
        veil.style.display = 'none';
        clearInterval(fade_out_animation);
        onClose();
        return;
      }

      opacity -= 0.1;
      veil.style.background = `rgba(0,0,0, ${opacity})`;
      body.style.opacity = opacity;
    }, 65);
  };

  return (
    <div>
      {
        <div className={cx(styles.modal, className)} ref={modal_veil} onClick={() => fadeOut()}>
          <div
            className={cx(styles.body, bodyClassName)}
            ref={modal_body}
            onClick={(e) => e.stopPropagation()}
          >
            {show_header && (
              <div className={styles.header}>
                {show_title ? (
                  <div className={styles.title}>
                    {' '}
                    <h5>{title}</h5>
                  </div>
                ) : (
                  <div></div>
                )}
                <span className={styles.closeIcon} onClick={closeModal}>
                  <Icon name="close" />
                </span>
              </div>
            )}
            <div className={styles.content}>
              {React.Children.map(children, (child) => {
                if (typeof child.type === 'string') return child;
                return React.cloneElement(child, {
                  closeModal,
                  fadeOut
                });
              })}
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default GmModal;
