import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as walletService from 'services/wallet';
import { addWallet } from 'store/actions/wallet';

const COST_SCHEDULE = [
  { min: 0, max: 499999, prices: { NGN: 1, USD: 0.00083 } },
  { min: 500000, max: 999999, prices: { NGN: 0.875, USD: 0.000723 } },
  { min: 1000000, max: 1499999, prices: { NGN: 0.8, USD: 0.00067 } },
  { min: 1500000, max: 1999999, prices: { NGN: 0.78, USD: 0.00065 } },
  { min: 2000000, max: 2999999, prices: { NGN: 0.75, USD: 0.00063 } },
  { min: 3000000, max: 3999999, prices: { NGN: 0.72, USD: 0.0006 } },
  { min: 4000000, max: 4999999, prices: { NGN: 0.7, USD: 0.00058 } },
  { min: 5000000, max: 9999999, prices: { NGN: 0.65, USD: 0.00054 } },
  { min: 10000000, max: Number.MAX_SAFE_INTEGER, prices: { NGN: 0.6, USD: 0.00083 } }
];

export const useWalletService = () => {
  const dispatch = useDispatch();
  const { tenant_id, token } = useSelector((state) => state.user_data);

  const checkBalanceSufficience = async () => {
    const { error, payload } = await walletService.checkCreditsLevel({ token });
    if (error) return toast.error(error);
    const { is_sufficient } = payload;
    return is_sufficient;
  };

  const createWallet = async () => {
    const { error, payload } = await walletService.create({ data: { tenant_id }, token });
    if (error) return toast.error('Could not retrieve wallet. Please reload page');
    return dispatch(
      addWallet({
        ...payload,
        balance: payload.balance ? payload.balance.toFixed(2) : 0
      })
    );
  };

  const createVirtualAccount = async (data) => {
    const { error, payload } = await walletService.createVirtualAccount({ data, token });
    if (error) {
      toast.error(error);
      return null;
    }

    updateWalletInStore({ virtual_account: { ...payload } });
    toast.success('Virtual account created successfully');
    return payload;
  };

  const determineTopUpCostSummary = ({
    credits_to_buy = 0,
    currency = 'NGN',
    expiration_date,
    tax_rate = 0,
    wallet_rate
  }) => {
    const milliseconds_in_month = 30 * 86400000;
    const today = Date.parse(new Date().toISOString().split('T')[0]);
    const exp_date = expiration_date || new Date().toISOString().split('T')[0];
    const exp_timestamp = Date.parse(exp_date);
    const duration = Math.round((exp_timestamp - today) / milliseconds_in_month) || 1;

    const monthly_volume = credits_to_buy / duration;
    const { prices } = COST_SCHEDULE.find(
      (schedule) => schedule.min <= monthly_volume && schedule.max > monthly_volume
    );
    const rate = wallet_rate || prices[currency];
    const amount = Math.round(credits_to_buy * rate);
    const tax = (Number(amount) * tax_rate) / 100;
    return { amount, exp_timestamp, tax };
  };

  const fetchWallet = async () => {
    const query_string = `tenant_id=${tenant_id}`;
    const { error, payload } = await walletService.read({ token, query_string });
    if (error) return toast.error(error);

    const {
      data: [wallet]
    } = payload;

    if (wallet && wallet.id) {
      return dispatch(
        addWallet({
          ...wallet,
          balance: wallet.balance ? wallet.balance.toFixed(2) : 0
        })
      );
    }

    createWallet();
  };

  const formatBalance = (count = 0) => {
    const integer_value = Number(count);

    if (integer_value > 9999 && integer_value <= 999999) {
      return `${(integer_value / 1000).toFixed(2)}K`;
    }

    if (integer_value > 999999) {
      return `${(integer_value / 1000000).toFixed(2)}M`;
    }

    return integer_value;
  };

  const updateWallet = async (data) => {
    const { payload } = await walletService.update({ token, data });
    return !!payload;
  };

  const updateWalletInStore = (data) => {
    dispatch(updateWallet({ ...data }));
  };

  return {
    checkBalanceSufficience,
    createVirtualAccount,
    createWallet,
    determineTopUpCostSummary,
    fetchWallet,
    formatBalance,
    updateWallet,
    updateWalletInStore
  };
};
