import React, { useEffect, useState } from 'react';
import { BasicInfoSection } from 'app/shared/info-section/basic';
import { DetailItem } from 'app/shared/info-section/item';
import { toast } from 'react-toastify';
import { CodeWrapper } from 'app/shared/wrappers/code';
import { InfoBanner } from 'app/shared/info-banner';
import { Spacer } from 'app/layouts/generic';

export const DKIMConfigurationDetails = ({ email_config }) => {
  const [dkim, setDKIM] = useState({});
  const [spf, setSPF] = useState({});

  useEffect(() => {
    if (email_config?.dkim) setDKIM(email_config.dkim);
    if (email_config?.spf) setSPF(email_config.spf);
  }, [email_config]);

  const handleCopy = (snippet) => {
    navigator.clipboard
      .writeText(snippet)
      .then(() => toast.info('Copied.'))
      .catch((e) => e);
  };

  return (
    <BasicInfoSection title="DNS configuration">
      <>
        <InfoBanner
          message={
            <span>
              You can learn how to configure your DNS records{' '}
              <a
                href="https://go-mailer.blog/how-to-configure-your-spf-and-dkim-records-in-cpanel"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                here{' '}
              </a>
            </span>
          }
          type="info"
        />
        <Spacer multiple={4} />
        <DetailItem title="SPF Record">
          <CodeWrapper text={spf?.value} onClick={handleCopy} info_text="click on record to copy" />
        </DetailItem>
        <DetailItem title="DKIM Host">
          <CodeWrapper text={`${dkim?.selector}._domainkey`} />
        </DetailItem>
        <DetailItem title="DKIM Record">
          <CodeWrapper
            text={dkim?.value}
            onClick={handleCopy}
            info_text="click on record to copy"
          />
        </DetailItem>
      </>
    </BasicInfoSection>
  );
};
