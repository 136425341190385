import cx from 'classnames';
import { Icon } from 'assets/icons';
import { useContactService } from 'hooks/contact';
import { useCampaignService } from 'hooks/mailing/campaign';
import { useMailingConfigService } from 'hooks/mailing/configuration';
import { useAudienceService } from 'hooks/users/audience';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './tracker.module.css';
import { useTutorialService } from 'hooks/files/tutorial';
import GmModal from 'app/shared/modal/modal';
import { OnboardingVideo } from '../video';
import { Spacer } from 'app/layouts/generic';
import { useNavigate } from 'react-router-dom';
import { useUserService } from 'hooks/iam/user';

const OnboardingItem = ({ title, status, onClick }) => {
  return (
    <div className={cx(styles.itemWrapper, status && styles.itemCompleted)} onClick={onClick}>
      <Icon name={status ? 'check_circle' : 'cancel'} />
      <span>{title}</span>
    </div>
  );
};

export const OnboardingTracker = () => {
  const navigate = useNavigate();
  const { id: user_id } = useSelector((store) => store.user_data);

  const { fetchAudiences } = useAudienceService();
  const { fetchCampaigns } = useCampaignService();
  const { fetchContacts } = useContactService();
  const { fetchConfiguration } = useMailingConfigService();
  const { fetchTutorials } = useTutorialService();
  const { fetchUserData } = useUserService();

  const [is_onboarded, setIsOnboarded] = useState(true);
  const [onboarding_status, setOnboardingStatus] = useState({});
  const [onboarding_videos, setOnboardingVideos] = useState({});
  const [show_video, setShowVideo] = useState(false);
  const [video, setVideo] = useState({});

  useEffect(() => {
    checkProgress();
    setInterval(() => {
      checkProgress();
    }, 60000);
  }, []);

  const checkProgress = () => {
    const query_string = `user_id=${user_id}&page=0&population=1`;
    Promise.all([
      fetchAudiences({ query_string }),
      fetchCampaigns({ query_string: `${query_string}&status=processed` }),
      fetchConfiguration(),
      fetchContacts({ query_string }),
      fetchTutorials({
        query_string:
          'code=contact_onboarding,campaign_onboarding,audience_onboarding,dns_onboarding'
      }),
      fetchUserData()
    ]).then(
      ([
        { size: audience_count },
        { size: campaign_count },
        {
          configurations: [configuration]
        },
        { size: contact_count },
        { tutorials },
        { user }
      ]) => {
        const { dkim, spf } = configuration || {};
        const dns_set = dkim?.selector && dkim?.value && spf?.value;
        const { onboarding } = user;
        const { has_visited_tutorials_page } = onboarding || {};

        setIsOnboarded(
          () =>
            dns_set &&
            !!audience_count & !!campaign_count & !!contact_count &&
            has_visited_tutorials_page
        );
        setOnboardingStatus(() => ({
          dns_set,
          audience_count,
          contact_count,
          campaign_count,
          has_visited_tutorials_page
        }));

        setOnboardingVideos(
          tutorials.reduce((sac, tutorial) => ({ ...sac, [tutorial.code]: tutorial }), {})
        );
      }
    );
  };

  const handleCloseVideo = () => {
    setShowVideo(false);
    setVideo(null);
  };

  const handleWatchVideo = (video = {}) => {
    setVideo(video);
    setShowVideo(true);
  };

  const handleRedirection = (url) => {
    navigate(url);
  };

  if (is_onboarded) return null;
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.title}>Account Setup</div>
        <Spacer multiple={2} />
        <OnboardingItem
          title="Create your first contact"
          status={!!onboarding_status.contact_count}
          onClick={() => handleWatchVideo(onboarding_videos.contact_onboarding)}
        />
        <OnboardingItem
          title="Segment your contacts"
          status={!!onboarding_status.audience_count}
          onClick={() => handleWatchVideo(onboarding_videos.audience_onboarding)}
        />
        <OnboardingItem
          title="Optimize for best results"
          status={!!onboarding_status.dns_set}
          onClick={() => handleWatchVideo(onboarding_videos.dns_onboarding)}
        />
        <OnboardingItem
          title="Send your first campaign"
          status={!!onboarding_status.campaign_count}
          onClick={() => handleWatchVideo(onboarding_videos.campaign_onboarding)}
        />
        <OnboardingItem
          title="Explore learning center"
          status={onboarding_status.has_visited_tutorials_page}
          onClick={() => handleRedirection('/tutorials')}
        />
      </div>

      <GmModal show_modal={show_video} onClose={handleCloseVideo}>
        <OnboardingVideo url={video?.urls?.video} title={video?.title} show_actions={false} />
      </GmModal>
    </>
  );
};
