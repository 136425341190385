import { Icon } from 'assets/icons';
import React from 'react';
import cx from 'classnames';
import styles from './input.module.css';

export const SimpleInput = ({
  type = 'text',
  label = '',
  name = '',
  input_id = '',
  className = '',
  wrapperClassName = '',
  disabled,
  placeholder = '',
  value = '',
  min = '',
  max = '',
  onBlur = () => {},
  onInput = () => {},
  onKeyDown = () => {},
  style = {},
  required = false,
  autoFocus = false,
  error = ''
}) => {
  return (
    <div className={cx(styles.formGroup, wrapperClassName)}>
      {label && (
        <label htmlFor={input_id}>
          {label}
          {required && <span className={styles.required}> *</span>}
        </label>
      )}
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        name={name}
        onChange={() => {}}
        onKeyDown={onKeyDown}
        onInput={(e) => (name ? onInput(e.target) : onInput(e.target.value))}
        onBlur={onBlur}
        className={cx(className, { [styles.errorBorder]: error })}
        style={style}
        id={input_id}
        disabled={disabled}
        min={min}
        max={max}
        autoFocus={autoFocus}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  );
};

export const IconInput = ({
  type = 'text',
  icon_name = 'add',
  label = '',
  input_id = '',
  className = '',
  iconClassName = '',
  wrapperClassName = '',
  disabled,
  placeholder = '',
  value = '',
  onBlur = () => {},
  onInput = () => {},
  onIconClick = () => {},
  style = {},
  icon_position = 'left',
  autoFocus,
  error = ''
}) => {
  const noPadding = icon_position === 'left';
  return (
    <div>
      <div className={cx(styles.iconFormGroup, wrapperClassName, { [styles.errorBorder]: error })}>
        {label && <label htmlFor={input_id}>{label}</label>}

        {icon_position === 'left' && (
          <span onClick={onIconClick} className={cx(styles.inputIcon, iconClassName)}>
            <Icon name={icon_name} />
          </span>
        )}
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          onBlur={onBlur}
          onInput={(e) => onInput(e.target.value)}
          onChange={() => {}}
          className={cx({ [styles.inputIconLeft]: noPadding }, className)}
          style={style}
          id={input_id}
          disabled={disabled}
          autoFocus={autoFocus}
        />
        {icon_position === 'right' && (
          <span onClick={onIconClick} className={styles.inputIcon}>
            <Icon name={icon_name} />
          </span>
        )}
      </div>
      {error && <span className={cx(styles.error, 'mb-4 d-inline-block')}>{error}</span>}
    </div>
  );
};
