import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as EventService from 'services/event';

export const useEventsService = () => {
  const { token } = useSelector((store) => store.user_data);

  const createEvent = async (options = {}) => {
    const { error, payload } = await EventService.create({ ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const fetchEvent = async (id, options = {}) => {
    const { error, payload } = await EventService.readById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  const fetchEvents = async (options = {}) => {
    const { error, payload } = await EventService.read({ ...options, token });
    if (error) {
      return { error, payload: null };
    }

    const {
      data: events,
      meta: { size }
    } = payload;
    return { events, size, error: null };
  };

  const isCustomEvent = (event) => {
    return event && event.type === 'user';
  };

  const updateEvent = async (id, options = {}) => {
    const { error, payload } = await EventService.updateById(id, { ...options, token });
    if (error) {
      toast.error(error);
      return null;
    }

    return payload;
  };

  return { createEvent, fetchEvent, fetchEvents, isCustomEvent, updateEvent };
};
