import { PageContentWrapper } from 'app/layouts/wrapper/page-content';
import { Routes, Route } from 'react-router-dom';
import ListAutomations from './list';

const AutomationModule = () => {
  return (
    <PageContentWrapper>
      <Routes>
        <Route path="/*" element={<ListAutomations />} />
      </Routes>
    </PageContentWrapper>
  );
};

export default AutomationModule;
