import React from 'react';
import styles from './badge.module.css';

export const Badge = ({ text, type = 'default' }) => {
  const style = {
    default: {
      color: 'var(--neutral-dark-2)',
      backgroundColor: 'var(--neutral-dark-5)'
    },
    blue: {
      color: 'var(--blue-primary)',
      backgroundColor: 'var(--blue-transparent)'
    },
    green: {
      color: 'var(--green-primary)',
      backgroundColor: 'var(--green-transparent-15)'
    },
    orange: {
      color: 'var(--orange-primary)',
      backgroundColor: 'var(--orange-transparent-15)'
    }
  };

  return (
    <div className={styles.wrapper} style={style[type]}>
      {text}
    </div>
  );
};
